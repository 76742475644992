<template>
    <div>
        <page-header btnNew="ACTIONS.NEW" pageTitle="CONFIG_SIMULATOR.INDEX_TITLE"
                     @newType="$router.push({name: 'createConfigSimulator', params: {action: 'adicionar'}})"/>
        <div class="card" v-if="items && !items.validations">
            <div v-for="(item, key) in items.data" :key="item.id" :class="key != 0 ? 'border-top2': ''">
                <div class="row gutters space-between">
                    <div class="list-primary hide-text">{{ item.name }}
                        <div v-if="item.open || item.default" class="font-weight-bold text-truncate"
                             style="font-size: smaller; color: grey">
                            {{ getMessageDescription(item.default, item.description, item.allotments_count) }}
                        </div>
                        <div class="font-weight-bold" style="font-size: smaller; color: grey">
                            {{ item.default ? '' : getMessageQuantityAllotments(item.allotments_count) }}
                        </div>
                    </div>
                    <div class="col-xl-1 col-lg-2 col-md-2 col-sm-2 col-2 mr-3 ml-auto mt-2">
                        <div class="text-right">
                            <a type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                               style="font-size: 20px" id="listDropdown" class="w-100">
                                <i class="icon-more_vert"/>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-black dropdown-menu-custom mw-100"
                                 style="min-width: min-content !important;"
                                 id="dropdownMenu">
                                <div class="dropdown-item pointer text-white"
                                     @click="$router.push({name: 'createConfigSimulator', params: {action: 'visualizar', id: item.id}})">
                                    <i class="icon-eye"/> {{ t('ACTIONS.VIEW') }}
                                </div>
                                <template v-if="!item.default">
                                    <div class="dropdown-item pointer text-white"
                                         @click="$router.push({name: 'createConfigSimulator', params: {action: 'editar', id: item.id}})">
                                        <i class="icon-mode_edit"/> {{ t('ACTIONS.EDIT') }}
                                    </div>
                                    <div class="dropdown-item pointer text-white"
                                         @click="duplicateSimulator(item.id)">
                                        <i class="icon-copy"/> {{ t('ACTIONS.DUPLICATE') }}
                                    </div>
                                    <div v-if="item.allotments_count === 0" class="dropdown-item pointer text-white"
                                         @click="deleteConfig(item.id)">
                                        <i class="icon-trash"/> {{ t('ACTIONS.DELETE') }}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-100 mr-2 unfold d-inline-flex" @click="open(item)">
                    <div class="row gutters ml-auto mr-auto mr-1"
                         title="Ver loteamentos" id="desdobrar">
                        <div class="d-inline-flex m-auto">
                            <div class="col-xl-12 col-lg-5 col-md-4 col-sm-4 text-center" v-if="!widthPhone()">
                                <div class="color-grey">
                                    <a class="button-center">
                                        <i class="icon-keyboard_arrow_down pointer"
                                           v-if="!item.open"/>
                                        <i class="icon-keyboard_arrow_up pointer" v-if="item.open"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="widthPhone()">
                            <div class="color-grey">
                                <a class="button-center">
                                    <i data-cy="user_index_show" class="icon-keyboard_arrow_down pointer"
                                       v-if="!item.open"></i>
                                    <i class="icon-keyboard_arrow_up pointer" v-if="item.open"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <transition name="fade">
                    <div v-show="item.open">
                        <template v-if="item.allotments_count > 0">
                            <div v-for="(allotment, index) in item.allotments" :key="allotment.id">
                                <div class="ml-4 mt-1 font-italic font-weight-bold">
                                    <span>{{ `${index + 1} - ${allotment.name}` }}</span>
                                    <hr class="p-0 m-0">
                                </div>
                            </div>
                        </template>
                        <div v-else>
                            <div class="ml-4 font-italic font-weight-bold">
                                <span>{{ t('CONFIG_SIMULATOR.NO_ALLOTMENT_LINKED') }}</span>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="card w-100" v-else>
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('CONFIG_SIMULATOR.NONE_FOUND_SIMULATOR_CONFIG') }}</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';
import Validate from '@/components/validate.vue';
import SimulatorConfig from '@/services/SimulatorConfig';
import PageHeader from '@/components/layouts/pageHeader.vue';

export default {
    name: 'indexConfigSimulatorComponent',

    mixins: [Validate],

    components: {
        PageHeader,
    },

    setup() {
        const {t} = useI18n();
        const toast = useToast();

        return {t, toast};
    },

    mounted() {
        this.index();
    },

    data() {
        return {
            items: [],
        };
    },

    methods: {
        index() {
            this.$store.commit('changeLoading', true);

            SimulatorConfig.index().then(resp => this.items = resp.data)
                .catch(err => this.errorMsg(err))
                .finally(() => this.$store.commit('changeLoading', false));
        },

        deleteConfig(id) {
            this.$swal.fire({
                title: this.t('GENERAL.MSG.REALLY_WANT_DELETE'),
                text: 'Essa ação é irreversível!',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Excluir',
                cancelButtonText: 'Cancelar',
            }).then(res => {
                if (res.isConfirmed) {
                    SimulatorConfig.delete(id)
                        .then(() => this.toast.success(this.t('CONFIG_SIMULATOR.DELETED_CONFIG_SIMULATOR')))
                        .catch(err => this.errorMsg(err)).finally(() => this.index());
                }
            });
        },

        duplicateSimulator(id) {
            this.$swal.fire({
                title: this.t('GENERAL.MSG.REALLY_WANT_DUPLICATE'),
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Duplicar',
                cancelButtonText: 'Cancelar',
            }).then(result => {
                if (result.isConfirmed) {
                    SimulatorConfig.duplicate(id).then(() => this.toast.success(this.t('GENERAL.MSG.DUPLICATED_SUCCESSFUL')))
                        .catch(err => this.errorMsg(err)).finally(() => this.index());
                }
            });
        },

        paddingGroup() {
            return this.widthPhone() ? {
                'padding-left': '1rem',
                'padding-right': '1rem',
                'margin-top': '0.5rem',
                'margin-bottom': '0.5rem',
            } : {'padding-right': '1.5rem'};
        },

        widthPhone(size = 480, smaller = '<') {
            if (smaller == '<') {
                return window.innerWidth < size;
            } else {
                return window.innerWidth > size;
            }
        },

        getMessageDescription(isDefault, description, quantity) {
            if (isDefault) {
                return `${description} (${quantity > 1 ? `${quantity} loteamentos` : `${quantity} loteamento`})`;
            }

            return description;
        },

        getMessageQuantityAllotments(quantity) {
            const plural = quantity > 1 ? 'loteamentos' : 'loteamento';

            return `Usada em ${quantity} ${plural}`;
        },

        open(config) {
            config.open = !config.open;
        },
    },
};

</script>

<style scoped>
.unfold:hover {
    border-radius: 5px;
    background-color: rgba(128, 128, 128, 0.3);
    cursor: pointer;
}
</style>